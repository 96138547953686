<template>
  <div class="row config-item" v-if="showConfig">
    <div class="col-12 col-md-7">
      <h3>{{ spec.label }}</h3>
      <p class="mb-0" v-for="(item, index) in formItems" :key="index">
        <template v-if="item.quantity === 0">
          No {{ specLabel }} chosen yet.
        </template>
        <template v-else>
          {{ item.quantity }} {{ item.componentName.toUpperCase() }}
        </template>
      </p>
    </div>
    <div class="col-12 col-md-5">
      <div v-if="isConfigurable">
        <el-button type="text" @click="configItem">Change</el-button>
      </div>
    </div>
    <el-dialog
      v-model="formFactorModal"
      title="Please select a type of form factor"
    >
      <div class="row">
        <div class="col-12">
          <p class="text-bold mb-4" :class="{ 'text-red': formFactorError }">
            Please select a form factor type.
          </p>
          <el-select
            v-model="formFactor"
            @change="setFormFactor"
            placeholder="Select a type of form factor"
          >
            <el-option
              v-for="factor in formFactors"
              :key="factor.id"
              :value="factor.id"
              :label="factor.label"
            />
          </el-select>
        </div>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button
            type="primary"
            :disabled="formFactorError"
            @click="setFormFactor"
            >Save</el-button
          >
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="showModal" :title="modalTitle" :destroy-on-close="true">
      <div class="row">
        <div class="col-12">
          <p
            class="text-bold mb-4"
            :class="{ 'text-red': showError }"
            v-if="slots"
          >
            This server has only {{ slots }} {{ slotLabel }} available.
          </p>
          <form-item
            v-for="(item, index) in formItems"
            :key="index"
            :item="item"
            :index="index"
            :spec="spec"
            :options="options"
            :maxSlots="slots"
            @remove="removeItem"
            @quantity="handleQuantity"
            @component="handleComponent"
          />
          <div
            class="col-12 btn-center"
            v-if="spec.select && !showError && spec.key !== 'cpu'"
          >
            <el-button type="primary" @click="addItem">Add</el-button>
          </div>
        </div>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button v-if="spec.key === 'storage'" @click="backToFormFactor"
            >Back</el-button
          >
          <el-button type="primary" :disabled="formError" @click="saveConfig"
            >Save</el-button
          >
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
// TODO : If key === 'storage' -> show choice for form_factor before showModal
// Then send @setFilter
import FormItem from '@/components/configure/items/FormItem';
export default {
  name: 'ServerConfig',
  props: ['spec', 'maxSlots', 'soc', 'config', 'filters'],
  emits: ['setFilter', 'setConfig'],
  components: {
    FormItem,
  },
  data() {
    return {
      showModal: false,
      formItems: [],
      modalTitle: '',
      showError: false,
      slots: 0,
      slotLabel: 'slot',
      component: {},
      isConfigurable: true,
      formError: false,
      specLabel: '',
      options: [],
      filter: {},
      formFactor: '',
      formFactorModal: false,
      formFactorError: false,
      formFactors: [
        {
          id: 'sff',
          label: 'SFF',
        },
        {
          id: 'lff',
          label: 'LFF',
        },
      ],
      showConfig: true,
    };
  },
  watch: {
    config() {
      this.formItems = [];
      this.getDefaultConfig();
    },
  },
  created() {
    this.getDefaultConfig();
    const component = this.spec.label.toLowerCase();

    this.specLabel = component;
    this.modalTitle = `Configure the ${component}`;

    this.slots = this.maxSlots[this.spec.key];
    this.filter = this.filters[this.spec.key];

    if (this.slots > 1) this.slotLabel = 'slots';
    if (this.soc === true && this.spec.key === 'cpu')
      this.isConfigurable = false;
  },
  methods: {
    addItem() {
      this.formError = true;
      let quantity = 0;
      for (let i in this.formItems) {
        quantity += this.formItems[i].quantity;
      }
      if (quantity < this.slots) {
        this.formItems.push({
          component: '',
          componentName: '',
          quantity: 0,
        });
      } else {
        this.showError = true;
      }
    },
    removeItem(index) {
      this.formItems.splice(index, 1);
      this.formError = false;
    },
    async configItem() {
      if (this.spec.key === 'storage') this.formFactorModal = true;
      else await this.configureItem();
    },
    async setFormFactor() {
      this.filter = this.formFactor;
      this.slots = this.maxSlots.storage[this.formFactor];
      this.formFactorModal = false;
      this.$emit('setFilter', this.spec.key, this.formFactor);
      await this.configureItem();
    },
    backToFormFactor() {
      this.showModal = false;
      this.formFactorModal = true;
    },
    async configureItem() {
      const options = await this.$store.dispatch('getComponentByType', {
        type: this.spec.key,
        filter: this.filter,
      });

      options.map((option) => {
        option.label = `${option.brand} ${option.name}`;
        return option;
      });

      this.options = options;
      this.showModal = true;
    },
    handleQuantity(quantity, index) {
      if (!this.spec.select) this.formError = false;

      this.formItems[index].quantity = quantity;
      let totalQuantity = 0;

      for (let i in this.formItems) {
        totalQuantity += this.formItems[i].quantity;
      }

      if (totalQuantity < this.slots) this.showError = false;
      else this.showError = true;
    },
    handleComponent(component, index) {
      if (!this.spec.select) return;
      this.formError = false;
      this.formItems[index].componentName = component.label;
      this.formItems[index].component = component.product_id;
    },
    async saveConfig() {
      let config = {};

      for (let i in this.formItems)
        config[this.formItems[i].component] = this.formItems[i].quantity;

      this.$emit('setConfig', this.spec.key, config);
      this.showModal = false;
    },
    getDefaultConfig() {
      const defaultConfig = this.config[this.spec.key];
      if (defaultConfig) {
        Object.keys(defaultConfig).forEach(async (key) => {
          const data = await this.$store.dispatch('getComponentById', {
            type: this.spec.key,
            id: key,
          });
          this.formItems.push({
            component: key,
            componentName: `${data.brand} ${data.name}`,
            quantity: defaultConfig[key],
          });
        });
      } else {
        this.formItems.push({
          component: '',
          componentName: '',
          quantity: 0,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.config-item {
  h3 {
    font-size: 20px;
    margin-bottom: 1.5rem;
  }
  .col-md-5 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .col-md-7 {
    text-align: center;
    margin-bottom: 1.5rem;
  }
}

.el-dialog {
  border-radius: 10px !important;
  margin-top: 5% !important;
  width: 90% !important;
  &__title {
    font-size: 24px !important;
    font-weight: 600 !important;
  }
  .el-input-number {
    margin-bottom: 1rem !important;
    max-width: 5rem !important;
    margin-right: 1rem;
  }
}

@include bp-up(md) {
  .el-dialog {
    width: 85% !important;
    .el-input-number {
      max-width: 10rem !important;
    }
    .el-select {
      width: 60% !important;
    }
  }
  .config-item {
    h3 {
      margin-bottom: 1rem;
    }
    .col-md-7 {
      padding-left: 3rem;
      text-align: start;
      margin-bottom: 0;
    }
  }
}

@include bp-up(lg) {
  .el-dialog {
    width: 80% !important;
    .el-input-number {
      width: 10rem !important;
      margin-right: 2rem;
    }
  }
}

@include bp-up(xl) {
  .el-dialog {
    width: 70% !important;
  }
}
</style>
