<template>
  <div class="form-item">
    <el-input-number
      controls-position="right"
      v-model="quantity"
      :min="0"
      :max="maxSlots"
      @change="handleQuantity"
      :disabled="disableQuantity"
    />
    <el-select
      v-model="component"
      v-if="spec.select"
      @change="handleComponent"
      :placeholder="`Select a ${label}`"
    >
      <el-option
        v-for="option in options"
        :key="option.product_id"
        :label="option.label.toUpperCase()"
        :value="option.product_id"
        >{{ option.label }}</el-option
      >
    </el-select>
    <span @click="removeItem" v-if="index"><i class="bi bi-x"></i></span>
  </div>
</template>

<script>

//TODO: Add persistance -  Need to reaccess item if no select change

export default {
  name: 'FormItem',
  props: ['item', 'index', 'spec', 'maxSlots', 'options'],
  emits: ['remove', 'quantity', 'component'],
  data() {
    return {
      quantity: this.item.quantity,
      component: this.item.component,
      disableQuantity: false
    };
  },
  computed: {
    label() {
      const label = this.spec.label;
      return label.toLowerCase();
    },
  },
  created() {
    if (!this.spec.select) {
      const component = {
        ...this.options[0],
        label: this.spec.label
      };
      this.$emit('component', component, this.index);
    }
    if (this.options.length === 0) this.disableQuantity = true;

  },
  methods: {
    removeItem() {
      this.$emit('remove', this.index);
    },
    handleQuantity() {
      this.$emit('quantity', this.quantity, this.index);
    },
    handleComponent() {
      const component = this.options.find(
        (option) => option.product_id === this.component
      );
      this.$emit('component', component, this.index);
    },
  },
};
</script>

<style lang="scss">
.form-item {
  margin-bottom: 1rem;
  display: flex;
  span {
    cursor: pointer;
    .bi-x {
      font-size: 20px;
      margin-left: 1rem;
    }
  }
}
</style>
