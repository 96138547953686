const serverConfig = [
  {
    label: 'Processor',
    key: 'cpu',
    select: true
  },
  {
    label: 'Memory Type',
    key: 'ram',
    select: true
  },
  {
    label: 'Storage',
    key: 'storage',
    select: true
  },
  {
    label: 'Fan',
    key: 'fan',
    select: false
  },
  {
    label: 'Power Supply',
    key: 'psu',
    select: false
  }
];

export default serverConfig;
